<template>
    <div>
        <div class="top">
            <div class="topleft">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/home' }">{{$t('home')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{$t('xtgl')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{$t('jegl')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{$t('editrole')}}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="cont">
            <el-form ref="form" :model="form" label-width="160px">
            <el-form-item :label="$t('rolename')">
                <el-input v-model="form.name" style="width:200px;"></el-input>
            </el-form-item>
            <el-form-item :label="$t('roleLe')">
                <el-input-number v-model="form.level" controls-position="right" :min="1" :max="999"></el-input-number>
            </el-form-item>
            <el-form-item :label="$t('data')">
                <el-select v-model="form.dataScope" placeholder="$t('qxz')">
                    <el-option :label="$t('quanbu')" value="全部"></el-option>
                    <el-option :label="$t('benji')" value="本级"></el-option>
                    <el-option :label="$t('zidy')" value="自定义"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('data')" v-if="form.dataScope=='自定义'">
                <el-cascader
                v-if="form.dataScope=='自定义' && ischange"
                v-model="form.depts"
                lazy
                :props="props"
                collapse-tags
                :show-all-levels="false"
                clearable></el-cascader>
                <i v-if="form.dataScope=='自定义'" class="el-icon-edit" style="cursor:pointer;color:#56A3F3;" @click="ischange=!ischange">{{$t('xiugai')}}</i>
            </el-form-item>
            <el-form-item :label="$t('rolems')">
                <el-input type="textarea" v-model="form.description" :autosize="{ minRows: 2, maxRows: 4}"  style="width:400px;"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">{{$t('baoc')}}</el-button>
            </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import apiurl from "@/api/sys"
import _api from "@/api/index"
export default {
    data(){
        return{
            form:{
                id:"",
                name:"",
                level:3,
                dataScope:"全部",
                depts:"",
                description :""
            },
            depts:[],
            ids:[],
            id:"",
            ischange:false,
        }
    },
    computed: {
        props() {
            return {
                lazy:true,
                label:"name",
                value:"id",
                multiple: true, 
                checkStrictly: true,
                lazyLoad: this.getchild
            }
        },
    },

    created(){
        this.id=this.$route.query.id
        this.getdetail()
        this.getdepts()
    },
    methods:{
        getdetail(){
            _api.get(apiurl.roles,this.id).then(res=>{
                console.log(res)
                this.form.id=res.id
                this.form.name=res.name
                this.form.level=res.level
                this.form.dataScope=res.dataScope
                var deptarr=[]
                for(let item of res.depts){
                    deptarr.push({id:item.id})
                }
                this.form.depts=deptarr
                this.form.description=res.description
            })
        },
        getdepts(){
            _api.get(apiurl.dept,{enabled: true }).then(res=>{
                this.depts=res.content
                
            })
        },
        onSubmit(){//添加
            if(this.form.dataScope=="自定义"){
                if(this.form.depts.length<=0){//判断是不是选了数据范围
                    this.$notify.error({
                        title:this.$t('error'),
                        message:this.$t('qxzsjfw')
                    })
                    return
                }
                if(this.form.depts[0].id=='' || this.form.depts[0].id==null){//如果没有改变数据范围
                    var zsarr=[]
                    for(let item of this.form.depts){
                        //item=item[item.length-1]
                        zsarr.push({id:item[item.length-1]})
                    }
                    this.form.depts=[]
                    this.form.depts=zsarr
                }
                
            }else{
                this.form.depts=null
            }

            _api.put(apiurl.roles,this.form).then(res=>{
                console.log(res)
                this.$notify.success({
                    title:this.$t('success'),
                    message:this.$t('editSuccess'),
                })
                this.$router.push("/role")
            }).catch(err=>{
                this.$notify.error({
                    title:this.$t('error'),
                    message:err.data.message
                })
            })
            
        },
        getchild(node, resolve){
            console.log(node)
            if(node.level==0){
                _api.get(apiurl.dept,{}).then(res=>{
                    console.log(res)
                    resolve(res.content);
                })
            }else{
                 _api.get(apiurl.dept,{pid:node.data.id}).then(res=>{
                    console.log(res)
                    resolve(res.content);
                })
            }
            
        }
    }
}
</script>